<template>
  <div class="container" @scroll="handleScroll">
    <message-card v-for="item in listData" :news="item" :key="item.id"></message-card>
  </div>
</template>

<script>
import MessageCard from "@/views/layout/components/message-card.vue";
import { getNews } from "@/api/member"
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 9,
      status: 1,
      listData: []
    }
  },
  methods: {
    getData() {
      const params = {
        page: this.pageIndex,
        limit: this.pageSize,
        tag: this.status
      }
      getNews(params).then(response => {
        this.listData.push(...response.data.data)
        this.$forceUpdate();
      })
    },
    handleScroll(event) {
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
        this.pageIndex += 1;
        this.getData()
      }
    }
  },
  mounted() {
    this.status = this.$route.params.status;
    this.getData()
  },
  components: {
    MessageCard
  }
}

</script>


<style scoped lang="scss">
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create three equal columns */
  gap: 1rem; /* Adjust the gap between items as needed */
  height: 72rem;
  overflow: auto;
}
/* 滚动条整体样式 */
.container::-webkit-scrollbar {
  width: 10px; /* 滚动条的宽度 */
}

/* 滚动条轨道样式 */
.container::-webkit-scrollbar-track {
  background-color: transparent; /* 背景颜色设置为透明 */
}

/* 滚动条滑块样式 */
.container::-webkit-scrollbar-thumb {
  background-color: #87CEEB; /* 天蓝色 */
  border: 2px solid #fff; /* 滑块边框 */
  border-radius: 10px; /* 大半圆的边框半径 */
}

/* 鼠标悬停在滚动条上时的样式 */
.container::-webkit-scrollbar-thumb:hover {
  background-color: #5F9EA0; /* 鼠标悬停时的颜色 */
}

</style>
