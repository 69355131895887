<template>
  <div class="container">
    <div class="tab-box">
      <div class="coupon-nav u-start-flex">
        <button
            class="cu-btn nav-btn u-flex-col u-row-center u-col-center"
            :class="{ 'btn-active': nav.id == stateCurrent }"
            v-for="nav in couponsState"
            :key="nav.id"
            @click="onNav(nav)"
        >
          <div class="item-title">{{ nav.title }}</div>
        </button>
      </div>
    </div>
    <div class="grid-box">
      <message-card :news="item" v-for="item in listData" :key="item.id"></message-card>
    </div>
    <div style="width: 80%; margin: 5rem auto" v-if="is_next" @click="loadMore"><el-button style="width: 100%;" type="primary">加载更多</el-button></div>
  </div>
</template>

<script>
import MessageCard from "@/views/layout/components/message-card.vue";
import { getNews } from "@/api/member"
export default {
  data() {
    return {
      stateCurrent: 1,
      listData: [],
      limit: 9,
      page: 1,
      is_next: false,
      couponsState: [
        {
          id: 1,
          title: '实时信息'
        },
        {
          id: 2,
          title: '我的推荐'
        },
        {
          id: 3,
          title: '经典展播'
        },
        {
          id: 4,
          title: '健康体育'
        },
        {
          id: 5,
          title: '社区风貌'
        }
      ]
    }
  },
  methods: {
    onNav(nav) {
      if (this.stateCurrent === nav.id) return;
      this.stateCurrent = nav.id;
      this.page = 1;
      this.listData = [];
      this.getData();
    },
    loadMore() {
      this.page += 1;
      this.getData();
    },
    getData() {
      const params = {
        limit: this.limit,
        page: this.page,
        tag: this.stateCurrent,
        new_status: true
      }
      getNews(params).then(response => {
        this.listData.push(...response.data.data)
        this.is_next = response.data.is_next;
      })
    }
  },
  created() {
    this.getData()
  },
  components: { MessageCard }
}

</script>

<style scoped lang="scss">
.grid-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create three equal columns */
  gap: 1rem; /* Adjust the gap between items as needed */
  margin-top: 2rem;
}
.coupon-nav {
  height: 5rem;
  .nav-btn {
    background-color: #FFFFFF;
    margin-right: 2rem;
    font-size: 1.8rem;
    color: #333333;
    width: 10%;
    height: 4rem;
    border-radius: 0.8rem;
    border: none;
    cursor: pointer;
  }
  .nav-btn:hover {
    color: #fff;
    background-color: #1896E8;
  }
  .btn-active {
    font-size: 1.8rem;
    color: #fff;
    background-color: #1896E8;
  }
}
</style>
