<template>
  <div class="container">
    <!-- 轮播图   -->
    <div class="carousel-box">
      <el-carousel arrow="always" height="55rem">
        <el-carousel-item v-for="item in listData" :key="item.id">
          <el-image class="carousel-img" fit="cover" :src="`${baseUrl}/${item.path}`"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <tab-grid style="width: 80%; margin: 0 auto"></tab-grid>
  </div>
</template>

<script>
import tabGrid from "@/views/layout/components/tab-grid.vue";
import { getCarousel } from "@/api/member"
export default {
  data() {
    return {
      listData: [],
      baseUrl: 'http://111.229.210.34:9013'
    }
  },
  components: {
    tabGrid
  },
  methods: {
    getData() {
      const params = {
        limit: 10,
        page: 1,
        carousel_status: true
      }
      getCarousel(params).then(response => {
        this.listData = response.data.data;
      })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__indicators--horizontal {
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.carousel-box {
  width: 80%;
  margin: 0 auto;
  padding: 2rem;
}

.carousel-img {
  width: 100%;
  height: 55rem;
}

</style>
