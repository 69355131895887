import request from "@/utils/requests"

export function getCarousel(params) {
    return request({
        url: '/api/system/carousel_show',
        method: 'get',
        params
    })
}

export function getNews(params) {
    return request({
        url: '/api/system/new_show',
        method: 'get',
        params
    })
}

export function newInfo(id) {
    return request({
        url: `/api/system/new_show/${id}`,
        method: 'get'
    })
}
