<template>
  <div class="card" @click="goToDetail">
    <el-image :src="`${baseUrl}/${news.image}`" fit="cover" class="card-img"></el-image>
    <div class="u-between-flex mt20 mb20">
      <button class="tag-btn">{{ news.tag | parseTag }}</button>
      <div>{{ news.release_date }}</div>
    </div>
    <div class="card-title">{{ news.title }}</div>
    <div class="u-between-flex">
      <div class="u-start-flex">
        <el-image :src="require('@/static/images/avatar.gif')" fit="cover" class="avatar-img"></el-image>
        <div style="font-size: 1.8rem; color: #666">{{ news.author }}</div>
      </div>
      <div style="color: #666; font-size: 1.6rem">
        <i class="el-icon-chat-dot-round"></i>&nbsp;{{ news.comments }}&nbsp;&nbsp;
        <i class="el-icon-view"></i>&nbsp;{{ news.views }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    news: Object
  },
  data() {
    return {
      baseUrl: 'http://111.229.210.34:9013'
    }
  },
  methods: {
    goToDetail() {
      this.$router.push({
        path: `/news/${this.news.id}`
      })
    }
  },
  filters: {
    parseTag(value) {
      if (value === 1) {
        return '实时信息'
      } else if (value === 2) {
        return '我的推荐'
      } else if (value === 3) {
        return  '经典展播'
      } else if (value === 4) {
        return '健康体育'
      } else if (value === 5) {
        return '社区风貌'
      }
    }
  }
}

</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
  padding: 1rem;
  .avatar-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .card-img {
    width: 100%;
    height: 20rem;
  }
  .tag-btn {
    height: 3.5rem;
    width: 12rem;
    line-height: 3.5rem;
    color: #ffffff;
    background-color: #558685;
    border: none;
  }
  .card-title {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 3rem;
  }
}
.card:hover {
  background-color: #fff;
}
</style>
