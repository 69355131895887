<template>
  <div class="layout">
    <div class="navigation">
      <div class="navigation-logo">
        <el-image style="width: 5rem" :src="require('@/static/images/member/huochuan.png')" fit="cover"></el-image>
        <div class="navigation-title">资讯中心</div>
      </div>
      <el-menu
          :default-active="activeMenu"
          active-text-color="#194D8F"
          router
          class="el-menu-vertical-demo">
        <el-menu-item :index="item.index" v-for="item in menuItems" :key="item.id">
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="operate">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        {
          id: 1,
          title: '实时信息',
          index: '/member/index/1'
        },
        {
          id: 2,
          title: '我的推荐',
          index: '/member/index/2'
        },
        {
          id: 3,
          title: '经典展播',
          index: '/member/index/3'
        },
        {
          id: 4,
          title: '健康体育',
          index: '/member/index/4'
        },
        {
          id: 5,
          title: '社区风貌',
          index: '/member/index/5'
        }
      ]
    }
  },
  computed: {
    activeMenu() {
      const route = this.$route
      const { path } = route
      return path
    },
  },
  components: {
  },
  created() {
    console.log(this.activeMenu)
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">
.layout {
  width: 75%;
  margin: 3rem auto;
  display: flex;
  justify-content: space-between;
  .navigation {
    width: 21%;
    height: auto;
    .navigation-logo {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: #fff;
      padding: 1.8rem 1.2rem;
      border: 0.1rem solid rgba(237,237,237,1);
      .navigation-title {
        font-size: 2.4rem;
        font-weight: bold;
        color: #194D8F;
      }
    }
  }
  .operate {
    background-color: #fff;
    width: 77%;
  }
}
</style>
