<template>
  <div class="container">
    <div style="font-size: 3rem">{{ news.title }}</div>
    <div class="head-box">
      <div><i class="el-icon-paperclip"></i>{{ news.tag | parseTag }}</div>
      <div class="u-start-flex">
        <div class="mr30">{{ news.release_date }}</div>
        <div class="mr30">{{ news.author }}</div>
        <div>
          <i class="el-icon-chat-dot-round"></i>&nbsp;{{ news.comments }}&nbsp;&nbsp;
          <i class="el-icon-view"></i>&nbsp;{{ news.views }}
        </div>
      </div>
    </div>
    <div class="body-box">
      <div v-html="news.content"></div>
    </div>
  </div>
</template>

<script>
import { newInfo } from "@/api/member"
export default {
  data() {
    return {
      newId: undefined,
      news: {}
    }
  },
  created() {
    this.newId = this.$route.params.id;
    this.getNewsInfo()
  },
  filters: {
    parseTag(value) {
      if (value === 1) {
        return '实时信息'
      } else if (value === 2) {
        return '我的推荐'
      } else if (value === 3) {
        return  '经典展播'
      } else if (value === 4) {
        return '健康体育'
      } else if (value === 5) {
        return '社区风貌'
      }
    }
  },
  methods: {
    getNewsInfo() {
      newInfo(this.newId).then(response => {
        this.news = response;
      })
    }
  }
}

</script>

<style scoped lang="scss">
.container {
  background-color: #fff;
  width: 65%;
  margin: 4rem auto;
  padding: 2rem;
  .head-box {
    font-size: 1.8rem;
    color: #666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    border-bottom: 1px solid #94C2DE;
  }
  .body-box {
    overflow: hidden;
  }
}
</style>
